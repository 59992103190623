export type Brand = "xsiter" | "yeda";
export type LogoColor = "primary" | "white";
export type Environment = "local" | "dev" | "prod";
export const domains: Record<
    Environment,
    {
        urls: string[];
        domains: string[];
    }
> = {
    local: {
        urls: ["https://2877-109-186-198-83.ngrok-free.app"],
        domains: ["2877-109-186-198-83.ngrok-free.app"],
    },
    dev: {
        urls: ["https://dev.app.xsiter.co.il", "https://dev.app.yedatech.io"],
        domains: ["dev.app.xsiter.co.il", "dev.app.yedatech.io"],
    },
    prod: {
        urls: ["https://app.xsiter.co.il", "https://app.yedatech.io"],
        domains: ["app.xsiter.co.il", "app.yedatech.io"],
    },
};

export const getLogoUrl = (brand: Brand, color: LogoColor) => {
    let brandName = "xsiter";
    if (brand === "yeda") {
        brandName = "yeda";
    }

    return `/images/branding/logo/${brandName}${
        color === "white" ? "-white" : ""
    }.svg`;
};
export const getFaviconUrl = (brand: Brand) => {
    switch (brand) {
        case "xsiter":
            return `/images/branding/favicon/xsiter.png`;
        case "yeda":
            return `/images/branding/favicon/yeda.png`;
        default:
            return `/images/branding/favicon/xsiter.png`;
    }
};
export const getBrand = (host: string): Brand => {
    if (host.includes("yeda")) {
        return "yeda";
    } else {
        return "xsiter";
    }
};
